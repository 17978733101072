import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField } from "@gonoodle/gn-universe-ui";
import { EyeOffIcon, EyeIcon } from "@heroicons/react/solid";
import Joi from "joi";

export default function PasswordField({ name, label, description, validate }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [field, meta] = useField({
    name,
    type: showPassword ? "text" : "password",
    validate: (value) => {
      const { error: joiError } = Joi.string()
        .required()
        .messages({
          "string.empty": "Please enter your password",
          "any.required": "Please enter your password",
        })
        .validate(value);

      if (joiError) {
        return joiError.message;
      }

      if (typeof validate === "function") {
        const error = validate(value);
        if (error) return error;
      }

      return undefined;
    },
  });

  return (
    <TextField
      name={field.name}
      label={label}
      description={description}
      value={meta.value}
      errorMessage={meta.touched ? meta.error : undefined}
      type={showPassword ? "text" : "password"}
      required={true}
      onInput={field.onChange}
      onBlur={field.onBlur}
      endAdornment={
        showPassword ? (
          <EyeOffIcon
            onClick={() => setShowPassword(false)}
            className="h-input-icon text-gray-300"
            data-testid="hide-password-icon"
          />
        ) : (
          <EyeIcon
            onClick={() => setShowPassword(true)}
            className="h-input-icon text-gray-300"
            data-testid="show-password-icon"
          />
        )
      }
    />
  );
}

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  validate: PropTypes.func,
};
