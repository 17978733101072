import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { DateField as PrimitiveDateField } from "@gonoodle/gn-universe-ui";
import { isValid } from "date-fns";

export default function DateField({
  name,
  label,
  description,
  required = false,
  validate,
}) {
  const [field, meta, helpers] = useField({
    name,
    type: "date",
    validate: (date) => {
      if (date === null) {
        return "Please enter a valid date";
      }

      if (!isValid(new Date(date?.year, date?.month, date?.day))) {
        return "Please enter a valid date";
      }

      if (typeof validate === "function") {
        const error = validate(date);
        if (error) return error;
      }

      return undefined;
    },
  });

  return (
    <PrimitiveDateField
      name={field.name}
      label={label}
      description={description}
      value={meta.value}
      errorMessage={meta.touched ? meta.error : undefined}
      required={required}
      onChange={(date) => {
        helpers.setValue(date);
      }}
      onBlur={() => {
        helpers.setTouched(true);
      }}
    />
  );
}

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
};
