import React from "react";
import Image from "next/image";

import { ROUTE_PATHS, URLS } from "../../constants";
import Link from "../Link";

export default function GoNoodleHeader({ className }) {
  return (
    <nav className={`gn-nav ${className}`}>
      <div className="flex items-center justify-center h-navbar">
        <div className="transform transition hover:scale-110">
          <Link
            href={ROUTE_PATHS.HOME}
            events={[
              {
                event: "Link Clicked",
                properties: {
                  title: "GoNoodle Logo",
                },
              },
            ]}
          >
            <span className="sr-only">GoNoodle</span>
            <Image
              width={150}
              height={25}
              src={`${URLS.GN_ASSETS_BASE_URL}/web_public/GoNoodleLogo_Registered_Reverse.svg`}
              alt="GoNoodle logo"
            />
          </Link>
        </div>
      </div>
    </nav>
  );
}
