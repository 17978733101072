import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import { TextField } from "@gonoodle/gn-universe-ui";
import { RefreshIcon } from "@heroicons/react/solid";
import Joi from "joi";

export default function EmailField({
  name,
  label,
  autoFocus,
  autoComplete,
  validate,
  validateAsync,
}) {
  const ref = React.useRef();
  const [isValidating, setIsValidating] = React.useState(false);
  const [field, meta] = useField({
    name,
    type: "email",
    validate: async (value) => {
      const { error: joiError } = Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.empty": "Please enter your email",
          "any.required": "Please enter your email",
          "string.email": "Please enter valid email",
        })
        .validate(value);

      if (joiError) {
        return joiError.message;
      }

      if (typeof validate === "function") {
        const error = validate(value);
        if (error) return error;
      }

      if (typeof validateAsync === "function") {
        try {
          setIsValidating(true);
          return await validateAsync(value);
        } finally {
          setIsValidating(false);
        }
      }
      return undefined;
    },
  });

  return (
    <TextField
      ref={ref}
      name={field.name}
      label={label}
      value={meta.value}
      errorMessage={meta.touched ? meta.error : undefined}
      type="email"
      required={true}
      autoFocus={autoFocus}
      onInput={field.onChange}
      onBlur={field.onBlur}
      autoComplete={autoComplete}
      endAdornment={
        isValidating ? (
          <RefreshIcon className="h-input-icon text-gray-300 animate-spin" />
        ) : null
      }
    />
  );
}

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  validate: PropTypes.func,
  validateAsync: PropTypes.func,
};
