import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";
import { AgeGatedPressable, Pressable } from "@gonoodle/gn-universe-ui";
import { twMerge } from "tailwind-merge";

import URLS from "../../constants/urls";

const googleStyle =
  "bg-white text-gray-500 rounded-sm border border-gray-300 focus:bg-gray-100 focus:border-gray-300 focus:ring-gray-100 hover:bg-gray-50 hover:text-gray-600 shadow-[0_4px_0_0_#CBCBCB,0_8px_0_0_rgba(0,0,0,0.25)]";
const cleverStyle =
  "text-white bg-[#436CF2] focus:bg-[#436CF2] hover:bg-[#6989f5] border border-transparent focus:border-[#819df6] focus:ring-[#819df6] focus:ring shadow-[0_4px_0_0_#3D62DC,0_8px_0_0_rgba(0,0,0,0.25)]";

export default function SSOButton({
  children,
  className,
  ageGate = false,
  onPress = () => {},
  onAgeGateFailed = () => {},
  variantStyle = "",
  disabled = false,
}) {
  const Button = ageGate ? AgeGatedPressable : Pressable;
  let icon;
  let buttonVariantClass;

  switch (variantStyle) {
    case "google":
      buttonVariantClass = googleStyle;
      icon = (
        <Image
          src={`${URLS.GN_ASSETS_BASE_URL}/web_public/icons/google.svg`}
          alt="Google icon"
          width="26"
          height="26"
        />
      );
      break;
    case "clever":
      buttonVariantClass = cleverStyle;
      icon = (
        <Image
          src={`${URLS.GN_ASSETS_BASE_URL}/web_public/icons/clever.svg`}
          alt="Clever icon"
          width="24"
          height="24"
        />
      );
      break;
    default:
      break;
  }

  return (
    <Button
      className={twMerge(
        className,
        buttonVariantClass,
        "text-md font-bold h-fit min-h-button-lg px-sm",
        disabled && "opacity-50",
      )}
      onPress={onPress}
      onAgeGateFailed={ageGate ? onAgeGateFailed : undefined}
      disabled={disabled}
    >
      <div className="flex flex-row items-center w-full px-3">
        {icon}
        <p className="px-2 mx-auto">{children}</p>
      </div>
    </Button>
  );
}

SSOButton.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  ageGate: PropTypes.bool,
  onPress: PropTypes.func,
  onAgeGateFailed: PropTypes.func,
  variantStyle: PropTypes.string,
  disabled: PropTypes.bool,
};
